import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Events are interactions that the webpage or the web application detects. Users generate events when they do one of the following:`}<ul><li>{`Clicks on the web page or web application.`}</li><li>{`Page scroll.`}</li><li>{`Form submissions.`}</li></ul>{`Automated workflows generate events when they do one of the following:`}<ul><li>{`Scheduled updates.`}</li><li>{`Data changes.`}</li></ul>{`CoreMedia Experience Platform generates events when it does one of the following:`}<ul><li>{`Page loads.`}</li><li>{`API responses`}</li><li>{`Error generation.`}</li></ul>{`CoreMedia Experience Platfor use events for web tracking, analytics, and dynamic user experiences.`}<br /><br />{`Events related panels contain the list of all events that users, workflows, and CoreMedia Experience platform generate and contain event associated metrics.`}</p>
    <p>{`The following tables list the data fields of Events listing panel, sorted by alphabetic order. Use `}<em parentName="p">{`CRTL + F`}</em>{` in Windows or `}<em parentName="p">{`Command + F`}</em>{` in MacOS to search inside the page.`}</p>
    <h2>{`Campaign Information`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External campaign type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If it exists, type of external campaign from which the customer requested the contact. For example, a Facebook campaign.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Contact Information`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the channel associated with the contact. One of the following: `}<ul><li>{`Voice`}</li><li>{`Video`}</li><li>{`Chat`}</li><li>{`Email`}</li><li>{`SMS`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Referrer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Source or link that referred the visitor.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Segment IDs`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Identifiers of the segments associated to the visitor. For example, `}<inlineCode parentName="td">{`A1B345C6DEFGH789I0JK`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Segments`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the segments associated to the visitor. For example, `}<inlineCode parentName="td">{`January Promo`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Simplified URL`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Simplified URL of the page that the visitor viewed. For example, `}<inlineCode parentName="td">{`https://documentation.coremedia.com/engagement-cloud/analytics`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Simplified referrer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Simplified URL that contains the link to the page that the visitor viewed. For example, `}<inlineCode parentName="td">{`https://documentation.coremedia.com/engagement-cloud/analytics`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`URL address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`URL address that the customer visited to reach the contact center.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Conversion Events`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Conversion Type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of the conversion. One of the following:`}<ul><li><strong parentName="td">{`assisted`}</strong>{`: A conversion has type `}<inlineCode parentName="td">{`assisted`}</inlineCode>{` if the visitor needed the help of an operator to end the conversion. For example, the customer had to talk to an operator to purchase a mobile phone or to submit a form.`}</li><li><strong parentName="td">{`direct`}</strong>{`: A conversion has type `}<inlineCode parentName="td">{`direct`}</inlineCode>{` if the customer was able to finalize the conversion without further help from an operator.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Conversion value`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If applicable, value of the conversion. For example, `}<inlineCode parentName="td">{`730€`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Days until conversion`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of days necessary for the customer to do the conversion. For example, `}<inlineCode parentName="td">{`29`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sessions until conversion`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Number of sessions that the customer opened before converting. For example, `}<inlineCode parentName="td">{`10`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Device Information`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Browser`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Browser that the using is using. For example, `}<inlineCode parentName="td">{`Chrome Android`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device family`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Brand family of the device that the customer used to access the website. For example, `}<inlineCode parentName="td">{`Galaxy A12`}</inlineCode>{`, `}<inlineCode parentName="td">{`IPhone`}</inlineCode>{`, or `}<inlineCode parentName="td">{`Macintosh`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device model`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Model of the device that the customer used to access the website. For example, `}<inlineCode parentName="td">{`SM-A125f`}</inlineCode>{`, `}<inlineCode parentName="td">{`Iphone 11`}</inlineCode>{`, or `}<inlineCode parentName="td">{`MacBook Pro`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device operating system`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`operating system of the device that the customer used to access the website. For example, `}<inlineCode parentName="td">{`Android`}</inlineCode>{`, `}<inlineCode parentName="td">{`Windows`}</inlineCode>{`, or  `}<inlineCode parentName="td">{`IPhone Osx`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of device. One of the following:`}<ul><li>{`Smartphone`}</li><li>{`Tablet`}</li><li>{`Desktop`}</li><li>{`Console`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Device vendor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Vendor of the device that the customer used to access the website. For example, `}<inlineCode parentName="td">{`Samsung`}</inlineCode>{`, `}<inlineCode parentName="td">{`Apple`}</inlineCode>{`, or `}<inlineCode parentName="td">{`HP`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Mobile device`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of te following:`}<ul><li>{`No - The visitor didn't use a mobile device to make the visit.`}</li><li>{`Yes - The visitor used a mobile device to make the visit.`}</li></ul></td>
        </tr>
      </tbody>
    </table>
    <h2>{`Events`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Browser resolution`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Graphical resolution of the browser in pixels. For example, `}<inlineCode parentName="td">{`1536x864`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Domain`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Accessed domain.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Event date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of the event.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Event name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the event.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Event type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of event in the page. One of the following:`}<ul><li>{`Active Elements`}</li><li>{`Abandonment Elements`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Internet provider`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the Internet provider of the customer. For example, `}<inlineCode parentName="td">{`Vodafone Portugal`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Organization`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the company that provides the Internet service to the visitor. For example, `}<inlineCode parentName="td">{`Vodafone`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Referrer domain`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Domain  of the page that contains the link that the visitor used to open the page. For example, `}<inlineCode parentName="td">{`https://www.google.com`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Search terms`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sequence of terms that the visitor used in the last search. For example, `}<inlineCode parentName="td">{`kitchen knive`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Location Information`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Country`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Country of the customer. For example, `}<inlineCode parentName="td">{`Portugal`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Region`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the city of the customer. For example, `}<inlineCode parentName="td">{`Lisbon`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Visitors Information`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Visitor id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier of the visitor.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      